@import "/src/styles/breakpoints";
@import "/src/styles/fonts";
@import "/src/styles/colors";

.container {
	grid-column: 1/-1;
	width: 100%;
	padding: 3rem 1.5rem 3rem 1.5rem;
}

.cardWrapper {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 1rem;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;

	h3 {
		@include title;
		color: $dark-green;
		margin: 0;
		grid-column: 1/-1;
	}

	p {
		@include content;
		color: $dark-green;
		margin: 0;
		grid-column: 1/-1;
	}

	@include md {
		h3 {
			grid-column: 2/-1;
			align-self: end;
		}

		p {
			grid-column: 2/-1;
			grid-row: 2;
		}
	}
}

.imgWrapper {
	grid-column: 1/-1;
	width: 100%;

	@include md {
		grid-column: 1/2;
		grid-row: 1 / span 2;
		width: 350px;
	}

	@include max-content {
		width: 490px;
	}

	img {
		display: block;
		width: 100%;

		@include md {
			object-fit: cover;
			height: 350px;
		}
	}

	> div {
		position: relative;
	}
}

.photoAlignRight {
	@include md {
		grid-column: 2/-1;
	}
}

.textAlignLeft {
	h3 {
		@include md {
			grid-column: 1/2;
		}
	}

	p {
		@include md {
			grid-column: 1/2;
		}
	}
}

:global(.slick-dots) {
	position: unset !important; // Override default react slick styles

	@include md {
		position: absolute !important; // Override default react slick styles
	}
}
