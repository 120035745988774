@font-face {
	font-family: "Lulo";
	src: local("Lulo"), url(../fonts/lulo-clean.ttf) format("truetype");
	font-display: swap;
}

@mixin title {
	font-family: "Lulo", sans-serif;
	color: $dark-text;
	font-size: 1.125rem;
	text-size-adjust: 100%;
}

@mixin content {
	font-family: "Roboto Slab", serif;
	color: $dark-text;
	font-size: 1rem;
	text-size-adjust: 100%;
}
