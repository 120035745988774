@font-face {
  font-family: "Lulo";
  src: local("Lulo"), url(../fonts/lulo-clean.ttf) format("truetype");
  font-display: swap;
}
* {
  box-sizing: border-box;
  list-style-type: none;
}
* button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}

.main-wrapper {
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 960px) {
  .main-wrapper {
    transition: 0.2s ease-in-out;
    grid-template-columns: calc(50vw - 36rem) 1fr calc(50vw - 36rem);
  }
}

.section-wrapper {
  display: grid;
  grid-template-columns: calc(50vw - 36rem) 1fr calc(50vw - 36rem);
  grid-column: 1/-1;
  background-color: white;
}

.inline-link {
  color: #245459;
  text-decoration: none;
  border-bottom: 2px dotted #245459;
  font-weight: 700;
}

.inline-link-white {
  color: white;
  text-decoration: none;
  border-bottom: 2px dotted white;
  font-weight: 700;
}

.lazyload-wrapper {
  display: contents;
}

.nav-bar-logo {
  width: 6rem;
  padding-top: 5px;
  cursor: pointer;
}
@media (min-width: 960px) {
  .nav-bar-logo {
    transition: 0.2s ease-in-out;
    width: 8rem;
  }
}

.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f2f2f2 8%, #ececec 18%, #f2f2f2 33%);
  background-size: 1200px 100%;
}
@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.text-button {
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 0.8rem;
  color: #333333;
  transition: ease-in-out 0.2s;
  text-decoration: none;
  text-align: center;
}

.text-btn-icon-wrapper {
  grid-template-columns: auto auto;
  gap: 0.5rem;
}

.menu {
  border-style: none;
}
.menu:hover {
  color: #fc8877;
}
.menu:active {
  color: #245459;
}

.side-menu-btn {
  color: white;
  border-style: none;
}
.side-menu-btn:hover {
  color: #fc8877;
}
.side-menu-btn:active {
  color: #245459;
}

.selected-side {
  color: #fc8877;
}

.selected {
  color: #245459;
  position: relative;
  transition: ease-in-out 0.2s;
}
.selected::after {
  position: absolute;
  width: 100%;
  border-bottom: solid 2px;
  left: -1.2px;
  content: "";
}

.outlined {
  color: #245459;
  border: 2px solid #245459;
  padding: 0.3rem 3rem 0.4rem 3rem;
}
.outlined:hover {
  background: #245459;
  color: white;
}

.outlined-shorter {
  color: #245459;
  border: 2px solid #245459;
  padding: 0.3rem 2rem 0.4rem 2rem;
}
.outlined-shorter:hover {
  background: #245459;
  color: white;
}

.outlined-white {
  color: white;
  border: 2px solid white;
  padding: 0.3rem 1rem 0.4rem 1rem;
}
.outlined-white:hover {
  background: white;
  color: #3f676c;
}

.submenu {
  font-size: 0.7rem;
  color: white;
  border: 2px solid white;
  padding: 0.3rem 0.5rem 0.4rem 0.5rem;
}
.submenu:hover {
  background: white;
  color: #333333;
}
@media (min-width: 960px) {
  .submenu {
    transition: 0.2s ease-in-out;
    font-size: 0.8rem;
  }
}

.gen-header-wrapper {
  padding: 0 1.5rem 0 1.5rem;
}

.header-cont {
  height: 20rem;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto min-content;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.header-cont .title-container {
  display: grid;
  align-self: center;
  grid-template-columns: 1fr;
  justify-items: left;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
}
.header-cont h1 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  font-size: 2rem;
  margin: 0;
  position: relative;
}
@media (min-width: 360px) {
  .header-cont h1 {
    font-size: 2.3rem;
  }
}
.header-cont .title-line {
  display: block;
  width: 5rem;
  height: 7px;
  background-color: white;
}
.header-cont .button-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 12rem;
  gap: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  height: -moz-max-content;
  height: max-content;
}
@media (min-width: 960px) {
  .header-cont .button-container {
    transition: 0.2s ease-in-out;
    grid-template-columns: repeat(3, 14rem);
  }
}

.menu-spacer {
  height: 5rem;
  grid-column: 1/-1;
}
@media (min-width: 960px) {
  .menu-spacer {
    transition: 0.2s ease-in-out;
    height: 7rem;
  }
}

.nav-wrapper {
  grid-column: 1/-1;
  display: grid;
  background-color: white;
  height: 5rem;
  width: 100%;
  top: 0;
  position: fixed;
  transform: translate3d(0, -100%, 0);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  padding: 0 1rem 0 1.5rem;
  z-index: 100;
}
@media (min-width: 960px) {
  .nav-wrapper {
    transition: 0.2s ease-in-out;
    height: 7rem;
    padding: 0 1.5rem 0 1.5rem;
  }
}

nav {
  margin: 0;
  grid-column: 1/-1;
  height: 5rem;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  justify-self: center;
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}
@media (min-width: 960px) {
  nav {
    transition: 0.2s ease-in-out;
    height: 7rem;
  }
}

.visible {
  transform: none;
}

.horizontal-list {
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
}
@media (min-width: 960px) {
  .horizontal-list {
    transition: 0.2s ease-in-out;
    gap: 2rem;
    grid-template-columns: repeat(7, auto);
  }
}

.hamb-icon {
  z-index: 100;
}
@media (min-width: 960px) {
  .hamb-icon {
    transition: 0.2s ease-in-out;
    display: none;
  }
}

.hamb {
  z-index: 100;
  position: relative;
  cursor: pointer;
  float: right;
  height: 5rem;
  width: 4rem;
  background: none;
  border: none;
  text-align: -webkit-center;
}

.hamb-line {
  background: #333333;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
}

.hamb-line::before,
.hamb-line::after {
  background: #333333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  width: 100%;
}

.hamb-line::before {
  top: 7px;
}

.hamb-line::after {
  top: -7px;
}

.side-menu {
  display: none;
}

.side-menu-container {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #245459;
  z-index: 10;
  padding: 4.5rem 2.4rem 3rem 0;
  box-shadow: -4px 4px 11px 0px rgba(58, 58, 58, 0.6117647059);
  transform: translateX(120%);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}
.side-menu-container ul {
  z-index: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  text-align: right;
}
.side-menu-container ul > li {
  margin-left: auto;
}

.menu-open {
  transform: translateX(0);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.btn-open .hamb .hamb-line {
  background: white;
  background: transparent;
}
.btn-open .hamb .hamb-line::before {
  background: white;
  transform: rotate(-45deg);
  top: 0;
}
.btn-open .hamb .hamb-line::after {
  background: white;
  transform: rotate(45deg);
  top: 0;
}

.footer-wrapper {
  grid-column: 1/-1;
  bottom: 0;
  background: white;
  width: 100%;
  align-items: center;
  color: white;
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

.prayer-request-footer-wrapper {
  background-color: #fc8877;
  padding: 3rem 1.5rem;
}

.footer-content-wrapper {
  width: 100%;
  padding: 2rem;
  background-color: #245459;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}

.footer-content {
  padding: 2rem 0;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column: 1/-1;
  background-color: white;
  color: #245459;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.footer-content h4 {
  margin: 0;
}

.footer-socials {
  display: grid;
  grid-template-columns: auto auto;
  width: -moz-fit-content;
  width: fit-content;
  gap: 0.6rem;
  margin-left: auto;
  margin-right: auto;
  padding-right: 7px;
}
.footer-socials a {
  display: contents;
  color: white;
}

.footer-icon {
  cursor: pointer;
}

.footer-logo {
  filter: invert(29%) sepia(4%) saturate(7350%) hue-rotate(137deg) brightness(78%) contrast(78%);
  width: 7rem;
}

.footer-address p {
  color: white;
  font-size: 0.8rem;
  text-align: center;
}

.copyright {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
}

.bible-verse {
  grid-template-columns: auto minmax(10rem, 35rem) auto;
  background: #d5d5d5;
  text-align: center;
  padding: 4rem 1rem 4rem 1rem;
  gap: 1rem;
}
@media (min-width: 505px) {
  .bible-verse {
    padding: 4rem 2rem 4rem 2rem;
  }
}
@media (min-width: 960px) {
  .bible-verse {
    transition: 0.2s ease-in-out;
    grid-template-columns: auto minmax(10rem, 50rem) auto;
  }
}
.bible-verse h2 {
  grid-column: 2/3;
  font-family: "Roboto Slab", serif;
  color: #666666;
  font-size: 1.9rem;
  margin: 0rem;
}
@media (min-width: 960px) {
  .bible-verse h2 {
    transition: 0.2s ease-in-out;
    font-size: 2.5rem;
  }
}
.bible-verse h3 {
  grid-column: 2/3;
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: #5c7f83;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.home-header {
  display: grid;
  grid-template-columns: calc(50vw - 36rem) 1fr calc(50vw - 36rem);
  grid-column: 1/-1;
}
.home-header .header-content {
  background: #245459;
  grid-column: 1/-1;
  position: relative;
  width: 100%;
}
.home-header .header-content span {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  display: inline-block;
  font-size: 8vw;
  color: white;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  opacity: 60%;
  padding: 1.2rem;
  border: 5px solid rgba(255, 255, 255, 0.6);
}
@media (min-width: 369px) {
  .home-header .header-content span {
    font-size: 2.095rem;
  }
}
@media (min-width: 391px) {
  .home-header .header-content span {
    padding: 1.4rem;
    font-size: 2rem;
  }
}

.home-header-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  z-index: 5;
  width: 80%;
}
@media (min-width: 570px) {
  .home-header-title {
    text-align: center;
  }
}

.each-slide {
  display: block !important;
}
.each-slide div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 30rem;
  background-position: center;
  opacity: 75%;
}
@media (min-width: 1100px) {
  .each-slide div {
    transition: 0.2s ease-in-out;
    height: 35rem;
  }
}
@media (min-width: 1350px) {
  .each-slide div {
    transition: 0.2s ease-in-out;
    height: 40rem;
  }
}

.hero-container {
  padding: 4rem 2rem 4rem 2rem;
  display: grid;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 0.8fr;
  grid-template-rows: auto auto;
  grid-column: 1/-1;
  background-color: white;
}
@media (min-width: 391px) {
  .hero-container {
    padding: 4rem 2rem 4rem 2rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
}
@media (min-width: 419px) {
  .hero-container {
    padding: 4rem 3rem 4rem 3rem;
  }
}
@media (min-width: 450px) {
  .hero-container {
    max-width: 27rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 700px) {
  .hero-container {
    padding: 4rem 2rem 4rem 2rem;
    row-gap: 1rem;
    max-width: unset;
    margin-left: unset;
    margin-right: unset;
    grid-template-columns: 20rem 15rem;
    grid-template-rows: auto min-content auto;
    grid-column: 2/3;
  }
}
@media (min-width: 960px) {
  .hero-container {
    transition: 0.2s ease-in-out;
    grid-template-columns: 28rem 25rem;
    grid-template-rows: auto 4.5rem auto;
    padding: 5rem 2rem 5rem 2rem;
    row-gap: 1rem;
  }
}
.hero-container .hero-text {
  max-width: 25rem;
  justify-self: center;
}
@media (min-width: 960px) {
  .hero-container .hero-text {
    transition: 0.2s ease-in-out;
    justify-self: right;
  }
}
.hero-container h3 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 0.75rem;
  grid-row: 1/2;
  grid-column: 1/2;
  margin: 0;
  line-height: 1.5em;
}
@media (min-width: 350px) {
  .hero-container h3 {
    font-size: 0.8rem;
  }
}
@media (min-width: 370px) {
  .hero-container h3 {
    font-size: 0.9rem;
  }
}
@media (min-width: 700px) {
  .hero-container h3 {
    align-self: flex-end;
  }
}
@media (min-width: 960px) {
  .hero-container h3 {
    transition: 0.2s ease-in-out;
    font-size: 1.2rem;
  }
}
.hero-container p {
  grid-row: 2/3;
  grid-column: 1/-1;
  margin: 0;
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-weight: 500;
  line-height: 1.5em;
}
@media (min-width: 700px) {
  .hero-container p {
    grid-row: 2/3;
    grid-column: 1/2;
  }
}
.hero-container a {
  grid-column: 1/-1;
  align-self: flex-start;
}
@media (min-width: 700px) {
  .hero-container a {
    grid-row: 3/4;
    grid-column: 1/2;
  }
}
@media (min-width: 960px) {
  .hero-container a {
    transition: 0.2s ease-in-out;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.hero-container .img-container {
  grid-row: 1/2;
  position: relative;
}
@media (min-width: 700px) {
  .hero-container .img-container {
    width: 15rem;
    height: 15rem;
    grid-row: 1/-1;
    grid-column: 2/3;
  }
}
@media (min-width: 960px) {
  .hero-container .img-container {
    transition: 0.2s ease-in-out;
    justify-self: left;
    width: 25rem;
    height: 25rem;
  }
}
.hero-container .img-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media (min-width: 700px) {
  .hero-container .img-container::after {
    content: unset;
    display: unset;
    padding-bottom: unset;
  }
}
.hero-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-self: center;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 60% 50%;
     object-position: 60% 50%;
  border-radius: 50%;
}

.key-info-container {
  display: grid;
  grid-template-columns: calc(50vw - 36rem) 1fr calc(50vw - 36rem);
  grid-column: 1/-1;
  background-color: #f0f0f0;
  padding: 4rem 1rem 4rem 1rem;
}

.key-info {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: 1fr;
  justify-items: center;
  row-gap: 2rem;
  justify-items: left;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 960px) {
  .key-info {
    transition: 0.2s ease-in-out;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 2/3;
    justify-items: center;
    width: unset;
    margin-left: unset;
    margin-right: unset;
  }
}
.key-info .icon {
  color: #333333;
  padding-top: 5px;
}
.key-info .colored-icon {
  color: #fc8877;
  padding-top: 5px;
}

.info-tile-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;
  border: 3px solid transparent;
  padding: 0.13rem 1rem 0.2rem 0.4rem;
  border-radius: 2rem;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  cursor: default;
}
.info-tile-wrapper h4 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 0.9rem;
  margin: 0;
}
.info-tile-wrapper p {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  transition: 0.2s ease-in-out;
  border: 3px solid #fc8877;
}

.about-wrapper {
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (min-width: 1200px) {
  .about-wrapper {
    background-size: cover;
  }
}
@media (max-width: 599px) {
  .about-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=600&h=700&dpr=1&fit=crop&q=80");
  }
}
@media screen and (min-width: 600px) and (max-width: 999px) {
  .about-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=1000&h=700&dpr=1&fit=crop&q=80");
  }
}
@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .about-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=1250&h=700&dpr=1&fit=crop&q=80");
  }
}
@media (min-width: 1200px) {
  .about-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=1500&h=800&dpr=1&fit=crop&crop=bottom&q=80");
  }
}

.about-main {
  background-color: #d5d5d5;
  padding: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: auto;
}
.about-main h3 {
  max-width: 1000px;
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.about-main p {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 0;
}
@media (min-width: 960px) {
  .about-main {
    transition: 0.2s ease-in-out;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.side-by-side-wrapper {
  display: grid;
  grid-template-columns: calc(50vw - 36rem) 1fr calc(50vw - 36rem);
  grid-column: 1/-1;
  background-color: white;
}

.side-by-side-wrapper-mission {
  display: grid;
  grid-template-columns: calc(50vw - 36rem) 1fr calc(50vw - 36rem);
  grid-column: 1/-1;
  background-color: #245459;
}

.side-by-side {
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr;
  padding: 1.5rem;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
}
.side-by-side h3 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
}
.side-by-side p {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-weight: 400;
  line-height: 1.4em;
}
.side-by-side img {
  width: 100%;
  align-self: center;
}
@media (min-width: 1070px) {
  .side-by-side {
    grid-template-columns: 1fr 1fr;
    max-width: 1000px;
    padding: 0;
  }
  .side-by-side h3 {
    padding-top: 2rem;
  }
}

.pastors h3 {
  color: #245459;
}
.pastors p {
  color: #245459;
}
@media (min-width: 1000px) {
  .pastors p {
    padding-right: 2rem;
  }
}

.pastors-text {
  padding-bottom: 1.5rem;
}

.mission h3 {
  color: white;
}
.mission p {
  color: white;
}
.mission img {
  display: none;
}
@media (min-width: 1070px) {
  .mission h3 {
    padding-left: 2rem;
  }
  .mission p {
    padding-left: 2rem;
  }
  .mission img {
    display: unset;
  }
}

.faq-wrapper {
  grid-column: 1/-1;
  background-color: #d5d5d5;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.faq-tile-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 3rem 0 3rem 0;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.faq-tile-wrapper .faq-heading {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 0 2rem;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 0.5rem;
}
.faq-tile-wrapper .faq-heading h3 {
  grid-column: 1/2;
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 0.9rem;
  margin: 0;
}
.faq-tile-wrapper .faq-heading p {
  grid-column: 1/-1;
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}
.faq-tile-wrapper .faq-heading .help-icon {
  color: #fc8877;
  width: 4rem;
  height: 4rem;
}
@media (min-width: 330px) {
  .faq-tile-wrapper .faq-heading h3 {
    font-size: 1rem;
  }
}
@media (min-width: 350px) {
  .faq-tile-wrapper .faq-heading h3 {
    font-size: 1.1rem;
  }
}
@media (min-width: 380px) {
  .faq-tile-wrapper .faq-heading h3 {
    font-size: 1.2rem;
  }
}
@media (min-width: 395px) {
  .faq-tile-wrapper .faq-heading .help-icon {
    grid-row: 1/3;
    grid-column: 2/3;
    width: 6rem;
    height: 6rem;
  }
  .faq-tile-wrapper .faq-heading h3 {
    align-self: flex-end;
  }
  .faq-tile-wrapper .faq-heading p {
    grid-column: 1/2;
    align-self: flex-start;
  }
}
@media (min-width: 430px) {
  .faq-tile-wrapper .faq-heading .help-icon {
    width: 8rem;
    height: 8rem;
  }
}
@media (min-width: 530px) {
  .faq-tile-wrapper .faq-heading .help-icon {
    width: 10rem;
    height: 10rem;
  }
}
@media (min-width: 650px) {
  .faq-tile-wrapper .faq-heading .help-icon {
    width: 15rem;
    height: 15rem;
  }
  .faq-tile-wrapper .faq-heading h3 {
    font-size: 1.4rem;
  }
  .faq-tile-wrapper .faq-heading p {
    font-size: 1.3rem;
  }
}
@media (min-width: 870px) {
  .faq-tile-wrapper .faq-heading h3 {
    font-size: 1.5rem;
  }
  .faq-tile-wrapper .faq-heading p {
    font-size: 1.4rem;
  }
}
@media (min-width: 970px) {
  .faq-tile-wrapper .faq-heading .help-icon {
    width: 20rem;
    height: 20rem;
  }
  .faq-tile-wrapper .faq-heading h3 {
    font-size: 1.6rem;
  }
  .faq-tile-wrapper .faq-heading p {
    font-size: 1.5rem;
  }
}
.faq-tile-wrapper .faq-tile-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-tile {
  border: 2px solid #245459;
  box-shadow: 3px 3px 0 0 #5c7f83;
  padding: 1rem;
  margin-bottom: 1rem;
}
.faq-tile h4 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 0.95rem;
  margin-top: 0;
  margin-bottom: 0;
  color: #245459;
}
.faq-tile .tile-info p {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  margin: 0;
  margin-top: 0.5rem;
  color: #245459;
  font-size: 1rem;
}

.masonry-grid {
  display: flex;
  width: auto;
  gap: 1rem;
}

.programs-wrapper {
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (min-width: 1200px) {
  .programs-wrapper {
    background-size: cover;
  }
}
@media (max-width: 599px) {
  .programs-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=600&h=600&dpr=1&fit=crop&q=70&auto=compress&crop=bottom");
  }
}
@media screen and (min-width: 600px) and (max-width: 1199px) {
  .programs-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=1200&h=700&dpr=1&fit=crop&q=60&auto=compress");
  }
}
@media (min-width: 1200px) {
  .programs-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=1200&h=800&dpr=1&fit=crop&crop=bottom&q=60");
  }
}
@media (min-width: 1800px) {
  .programs-wrapper {
    background-size: cover;
  }
}

.white-bg {
  background-color: white;
}

.gray-bg {
  background-color: #f0f0f0;
}

.title-shimmer {
  height: 30px;
}

.text-shimmer {
  height: 20px;
  margin-top: 5px;
}

.image-shimmer {
  width: 100%;
  height: 300px;
}

.connect-wrapper {
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (min-width: 1200px) {
  .connect-wrapper {
    background-size: cover;
  }
}
@media (max-width: 599px) {
  .connect-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=600&h=600&dpr=1&fit=crop&q=70&auto=compress&crop=bottom");
  }
}
@media screen and (min-width: 600px) and (max-width: 1199px) {
  .connect-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=1200&h=700&dpr=1&fit=crop&q=60&auto=compress");
  }
}
@media (min-width: 1200px) {
  .connect-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=1200&h=800&dpr=1&fit=crop&crop=bottom&q=60");
  }
}

.rich-text {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
.rich-text a {
  color: #333333;
}

.contact-wrapper {
  grid-column: 1/-1;
}

.contact-header-wrapper {
  padding: 0 1.5rem 0 1.5rem;
  background-position: center;
  background-size: cover;
}
@media (max-width: 599px) {
  .contact-header-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/contact/church-front.jpeg?w=600&h=700&dpr=1&fit=crop&q=80");
  }
}
@media screen and (min-width: 600px) and (max-width: 999px) {
  .contact-header-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/contact/church-front.jpeg?w=1000&h=700&dpr=1&fit=crop&q=80");
  }
}
@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .contact-header-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/contact/church-front.jpeg?w=1250&h=700&dpr=1&fit=crop&q=80");
  }
}
@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .contact-header-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/contact/church-front.jpeg?w=1500&h=800&dpr=1&fit=crop&q=80");
  }
}
@media (min-width: 1500px) {
  .contact-header-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/contact/church-front.jpeg?w=1800&h=900&dpr=1&fit=crop&q=90");
  }
}

.contact-header-cont {
  height: 25rem;
  display: grid;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.contact-header-cont .title-container {
  display: grid;
  align-self: center;
  grid-template-columns: 1fr;
  justify-items: left;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  margin-top: auto;
  margin-bottom: 3rem;
}
.contact-header-cont h1 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  font-size: 2rem;
  margin: 0;
  position: relative;
}
@media (min-width: 360px) {
  .contact-header-cont h1 {
    font-size: 2.3rem;
  }
}
.contact-header-cont .title-line {
  display: block;
  width: 5rem;
  height: 7px;
  background-color: white;
}
@media (min-width: 960px) {
  .contact-header-cont {
    transition: 0.2s ease-in-out;
    height: 38rem;
  }
}

.map-wrapper {
  width: 100%;
  height: 30rem;
  grid-column: 1/-1;
}

.map-marker {
  display: grid;
  position: absolute;
  cursor: pointer;
  background: white;
  border: 1px solid #245459;
  width: 60px;
  height: 55px;
  justify-content: center;
  margin-left: -35px;
  margin-top: -65px;
  border-radius: 10px;
  padding: 1px 6px 8px 8px;
  box-shadow: 3px 3px 0 0 #5c7f83;
}
.map-marker::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 20px;
  border-width: 13px 13px 0;
  border-style: solid;
  border-color: #5c7f83 transparent;
  display: block;
  width: 0;
}
.map-marker::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 20px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: white transparent;
  display: block;
  width: 0;
  filter: drop-shadow(0px 1px 0 #245459);
}
.map-marker .church-icon {
  color: #245459;
  width: 35px;
  height: 35px;
  stroke: white;
  stroke-width: 1;
  margin-bottom: -2px;
}
.map-marker p {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 0.6rem;
  color: #245459;
  margin: 0;
  letter-spacing: 3px;
  margin-right: -3px;
  margin-left: 1px;
}

.contact-details-bg {
  background-color: #d5d5d5;
  padding: 0;
}

.contact-details-wrapper {
  background-color: #f0f0f0;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 750px) {
  .contact-details-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

.sunday-service-details {
  padding: 3rem 1.5rem 3rem 1.5rem;
  background-color: #3f676c;
  display: grid;
}
.sunday-service-details .ssd-content {
  align-self: center;
}
.sunday-service-details .ssd-content h3 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  margin-top: 0;
}
.sunday-service-details .ssd-content p {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  margin-bottom: 0.4rem;
}

.contact-form-wrapper {
  padding: 3rem 1.5rem 3rem 1.5rem;
}
.contact-form-wrapper h3 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: #fc8877;
  margin-top: 0;
}
.contact-form-wrapper form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.contact-form-wrapper form .errorMessage {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: #fc8877;
  font-size: 0.9rem;
  margin-top: 5px;
  margin-left: 2px;
}
.contact-form-wrapper input {
  display: block;
}
.contact-form-wrapper textarea {
  display: block;
}
.contact-form-wrapper .form-input {
  background-color: #f0f0f0;
  border: none;
  border-bottom: 2px solid #245459;
  border-radius: 0;
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 1rem;
  resize: none;
  outline-color: #5c7f83;
  width: 100%;
}
.contact-form-wrapper .submit-btn {
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-size: 0.8rem;
  color: #333333;
  transition: ease-in-out 0.2s;
  text-decoration: none;
  text-align: center;
  color: #245459;
  border: 2px solid #245459;
  padding: 0.3rem 3rem 0.4rem 3rem;
}
.contact-form-wrapper .submit-btn:hover {
  background: #245459;
  color: white;
}

.google-recap-note {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: #666666;
  font-size: 10px;
}
.google-recap-note a {
  color: #fc8877;
}

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --toastify-color-success: #fc8877 !important;
  --toastify-font-family: "Roboto Slab", sans-serif !important;
}

.give-wrapper {
  grid-column: 1/-1;
  background-color: #3f676c;
}

.give-header-wrapper {
  padding: 0 1.5rem 0 1.5rem;
}
.give-header-wrapper .give-header-bg {
  opacity: 60%;
  background-size: cover;
  position: absolute;
  margin: 0 -1.5rem 0 -1.5rem;
  width: 100%;
  height: 25rem;
}
@media (min-width: 960px) {
  .give-header-wrapper .give-header-bg {
    transition: 0.2s ease-in-out;
    height: 38rem;
  }
}
@media (max-width: 599px) {
  .give-header-wrapper .give-header-bg {
    background-position: 60% 50%;
    background-image: url("https://coastandvineschurch.imgix.net/give/flourish.jpeg?w=800&h=500&dpr=1&fit=crop&q=60");
  }
}
@media screen and (min-width: 600px) and (max-width: 999px) {
  .give-header-wrapper .give-header-bg {
    background-position: 100% 50%;
    background-image: url("https://coastandvineschurch.imgix.net/give/flourish.jpeg?w=1000&h=600&dpr=1&fit=crop&q=60");
  }
}
@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .give-header-wrapper .give-header-bg {
    background-image: url("https://coastandvineschurch.imgix.net/give/flourish.jpeg?w=1250&h=600&dpr=1&fit=crop&q=60");
  }
}
@media (min-width: 1200px) {
  .give-header-wrapper .give-header-bg {
    background-position: 60% 50%;
    background-image: url("https://coastandvineschurch.imgix.net/give/flourish.jpeg?w=1500&h=700&dpr=1&fit=crop&q=60");
  }
}

.give-header-cont {
  height: 25rem;
  display: grid;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.give-header-cont .give-title-container {
  display: grid;
  align-self: center;
  grid-template-columns: 1fr;
  justify-items: left;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  margin-top: auto;
  margin-bottom: 3rem;
}
.give-header-cont h1 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  font-size: 1.9rem;
  margin: 0;
  position: relative;
  width: -moz-min-content;
  width: min-content;
}
@media (min-width: 390px) {
  .give-header-cont h1 {
    font-size: 2.3rem;
  }
}
.give-header-cont .title-line {
  display: block;
  width: 5rem;
  height: 7px;
  background-color: white;
  z-index: 2;
}
@media (min-width: 960px) {
  .give-header-cont {
    transition: 0.2s ease-in-out;
    height: 38rem;
  }
}

.give-section-wrapper {
  background-color: #d5d5d5;
}
.give-section-wrapper .give-details-wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 600px) {
  .give-section-wrapper .give-details-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 900px) {
  .give-section-wrapper .give-details-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.give-tile-bg {
  height: 270px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  align-content: center;
}
.give-tile-bg .give-tile-text {
  grid-column: 1/-1;
}
.give-tile-bg .give-tile-text h3 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  margin-top: 0;
}
.give-tile-bg .give-tile-text p {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: white;
  margin-bottom: 0;
}
.give-tile-bg .give-tile-text p a {
  color: white;
}
.give-tile-bg .white h3 {
  color: white;
}
.give-tile-bg .white p {
  color: white;
}
.give-tile-bg .dark h3 {
  color: #333333;
}
.give-tile-bg .dark p {
  color: #333333;
}
@media (min-width: 1015px) {
  .give-tile-bg {
    width: 333.3333333333px;
    height: 333.3333333333px;
  }
}

.dark-gray {
  background-color: #333333;
}

.middle-gray {
  background-color: #666666;
}
.middle-gray .give-icon {
  stroke: #666666;
}

.dark-peach {
  background-color: #fb6a48;
}

.light-green {
  background-color: #5c7f83;
}
.light-green .give-icon {
  stroke: #5c7f83;
}

.middle-green {
  background-color: #3f676c;
}

.peach {
  background-color: #fc8877;
}
.peach .give-icon {
  stroke: #fc8877;
}

.light-gray {
  background-color: #999999;
}

.lighter-gray {
  background-color: #d5d5d5;
}
.lighter-gray .give-icon {
  stroke: #d5d5d5;
}

.give-icon-container {
  grid-column: 1/-1;
  align-self: center;
  justify-self: center;
  width: -moz-fit-content;
  width: fit-content;
}
.give-icon-container .give-icon {
  color: white;
  width: 150px;
  height: 150px;
  stroke-width: 1;
}

.church-center-wrapper {
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (min-width: 1200px) {
  .church-center-wrapper {
    background-size: cover;
  }
}
@media (max-width: 599px) {
  .church-center-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=600&h=700&dpr=1&fit=crop&q=80");
  }
}
@media screen and (min-width: 600px) and (max-width: 999px) {
  .church-center-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=1000&h=700&dpr=1&fit=crop&q=80");
  }
}
@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .church-center-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=1250&h=700&dpr=1&fit=crop&q=80");
  }
}
@media (min-width: 1200px) {
  .church-center-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/about/banner.jpeg?w=1500&h=800&dpr=1&fit=crop&crop=bottom&q=80");
  }
}

.app-btn-cont {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.store-badge {
  height: 40px;
}

.app-download-container {
  grid-column: 1/-1;
  width: 100%;
  padding: 3rem 1.5rem 3rem 1.5rem;
  background: white;
}

.app-download-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.app-download-wrapper h3 {
  font-family: "Lulo", sans-serif;
  color: #333333;
  font-size: 1.125rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: #245459;
  margin: 0;
  grid-column: 1/-1;
}
.app-download-wrapper p {
  font-family: "Roboto Slab", serif;
  color: #333333;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: #245459;
  margin: 0;
  grid-column: 1/-1;
}
@media (min-width: 760px) {
  .app-download-wrapper h3 {
    grid-column: 2/-1;
    align-self: end;
  }
  .app-download-wrapper p {
    grid-column: 2/-1;
    grid-row: 2;
  }
}

.video-wrapper {
  grid-column: 1/-1;
  width: 100%;
  grid-row: 3;
}
@media (min-width: 760px) {
  .video-wrapper {
    grid-column: 1/2;
    grid-row: 1/span 2;
    width: 400px;
  }
}
@media (min-width: 850px) {
  .video-wrapper {
    width: 450px;
  }
}
@media (min-width: 1050px) {
  .video-wrapper {
    transition: 0.2s ease-in-out;
    width: 490px;
  }
}
.video-wrapper img {
  display: block;
  width: 100%;
}
@media (min-width: 760px) {
  .video-wrapper img {
    -o-object-fit: cover;
       object-fit: cover;
    height: 350px;
  }
}
.video-wrapper > div {
  position: relative;
}

@media (min-width: 760px) {
  .align-video-right {
    grid-column: 2/-1;
  }
}

@media (min-width: 760px) {
  .text-align-left h3 {
    grid-column: 1/2;
  }
}
@media (min-width: 760px) {
  .text-align-left p {
    grid-column: 1/2;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.no-route-wrapper {
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (min-width: 1200px) {
  .no-route-wrapper {
    background-size: cover;
  }
}
@media (max-width: 599px) {
  .no-route-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=600&h=600&dpr=1&fit=crop&q=70&auto=compress&crop=bottom");
  }
}
@media screen and (min-width: 600px) and (max-width: 1199px) {
  .no-route-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=1200&h=700&dpr=1&fit=crop&q=60&auto=compress");
  }
}
@media (min-width: 1200px) {
  .no-route-wrapper {
    background-image: url("https://coastandvineschurch.imgix.net/programs/header.jpeg?w=1200&h=800&dpr=1&fit=crop&crop=bottom&q=60");
  }
}

.no-route-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 0;
}

.return-home-btn-wrapper {
  display: flex;
  align-self: center;
}/*# sourceMappingURL=styles.css.map */