$screen-min: 320px;
$screen-sm-min: 600px;
$screen-md-min: 760px;
$screen-med-min: 960px;
$screen-lrg-min: 1100px;
$screen-xlrg-min: 1350px;
$screen-max-content: 1050px;

@mixin min {
  @media (min-width: #{$screen-min}) {
    transition: 0.2s ease-in-out;
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin med {
  @media (min-width: #{$screen-med-min}) {
    transition: 0.2s ease-in-out;
    @content;
  }
}

@mixin lrg {
  @media (min-width: #{$screen-lrg-min}) {
    transition: 0.2s ease-in-out;
    @content;
  }
}

@mixin xlrg {
  @media (min-width: #{$screen-xlrg-min}) {
    transition: 0.2s ease-in-out;
    @content;
  }
}

@mixin max-content {
  @media (min-width: #{$screen-max-content}) {
    transition: 0.2s ease-in-out;
    @content;
  }
}

@mixin custom-size($screen) {
  @media (min-width: #{$screen+'px'}) {
    @content;
  }
}

@mixin custom-size-max($screen) {
  @media (max-width: #{$screen+'px'}) {
    @content;
  }
}
