$dark-text: #333333;
$middle-gray: #666666;
$dark-green: #245459;
$middle-green: #3f676c;
$light-green: #5c7f83;
$peach: #fc8877;
$dark-peach: #fb6a48;
$white: white;
$lightest-gray: #f0f0f0;
$lighter-gray: #d5d5d5;
$light-gray: #aaaaaa;
$not-so-light-gray: #999999;
