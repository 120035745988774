@import "/src/styles/breakpoints";
@import "/src/styles/fonts";
@import "/src/styles/colors";

// PRAYER REQUEST FORM //
.wrapper {
	max-width: 1000px;
	margin: 0 auto;

	h3 {
		@include title;
		color: $white;
		margin: 0;
	}
	p {
		@include content;
		color: $white;
		margin-bottom: 1.5rem;
	}

	form {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		.errorMessage {
			@include content;
			color: $dark-green;
			font-size: 0.9rem;
			margin-top: 5px;
			margin-left: 2px;
		}
	}

	input {
		display: block;
	}

	textarea {
		display: block;
	}

	.formInput {
		background-color: $peach;
		border: 2px solid $white;
		border-radius: 0;
		@include content;
		font-size: 1rem;
		resize: none;
		outline-color: $white;
		width: 100%;
		color: $white;
	}

	::placeholder {
		color: rgb(230, 230, 230);
	}

	.submitBtn {
		background: none;
		cursor: pointer;
		margin: 0;
		padding: 0;
		@include title;
		font-size: 0.8rem;
		color: $dark-text;
		transition: ease-in-out 0.2s;
		text-decoration: none;
		text-align: center;
		color: $white;
		border: 2px solid $white;
		padding: 0.3rem 3rem 0.4rem 3rem;

		&:hover {
			background: $white;
			color: $peach;
		}

		@include custom-size(600) {
			width: 15rem;
		}
	}
}

.collapsible {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
}

.open {
	max-height: 500px;
	transition: max-height 0.3s ease-in-out;
}

.toggleBtn {
	display: flex;
	width: 100%;
	background: none;
	border: 0;
	@include title;
	color: $white;
	cursor: pointer;
	padding: 0%;
	transition: all 0.2s ease-in-out;
	justify-content: space-between;

	@include custom-size(530) {
		display: block;
		width: auto;
		margin: 0 auto;
	}
}

.chevron {
	margin: 0 0 -7px 5px;
}
